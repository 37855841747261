import { ObservableQuery, useApolloClient } from '@apollo/client';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '~/components/context/Authentication';
import { Event, EventContext } from '~/components/context/Event';
import {
  Cart,
  CartPartsItemInputCustom,
  ClearPaymentMethodOnCartInput,
  CommerceSupplierDeliveryOptionInput,
  SetBillingAddressOnCartInput,
  SetPaymentMethodOnCartInput,
  useAddPartsProductsToCartMutation,
  useApplyCouponMutation,
  useClearPaymentMethodOnCartMutation,
  usePartnerPartsCartQuery,
  usePlacePartsOrderMutation,
  useRemoveCouponMutation,
  useRemoveItemsFromCartMutation,
  useSetAddressesOnCartMutation,
  useSetPartsShippingMethodsOnCartMutation,
  useSetPaymentMethodOnCartMutation,
  useUpdateCartItemsMutation,
} from '~/operations';
import { placedOrder } from '~/state/checkout';
import GA from '~/utils/ga';
import { useAlerts } from '~/utils/useAlerts';

interface ProductsSupplierBranch {
  branch: string;
  supplier: string;
  products: string[];
}

export enum PlaceOrderReturn {
  OK = 'OK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  GENERIC_ERROR = 'GENERIC_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
interface CartContextProps {
  loading: boolean;
  id?: string;
  cart?: Cart;
  refetch: ObservableQuery['refetch'];
  addItem: (props: any, itemNames: string[]) => Promise<boolean | any>;
  setShippingMethod: (shippingMethods: Array<CommerceSupplierDeliveryOptionInput>) => Promise<any>;
  loadingShippingMethods: boolean;
  updateCart: (props: SimpleCartItemProps[]) => Promise<string>;
  removeItems: (cartItemIds: Array<number>) => void;
  clearCart: () => void;
  getGrandTotal: Function;
  setShippingAddress: (resetPayment?: boolean) => void;
  resetCart: () => void;
  placeOrder: (invoiceObs?: string) => Promise<[PlaceOrderReturn, string?]>;
  setPaymentMethod: (input: SetPaymentMethodOnCartInput) => void;
  clearPaymentMethod: (input: ClearPaymentMethodOnCartInput) => void;
  setCoupon: (code: string) => void;
  removeCoupon: () => void;
  setLoading: (param: boolean) => void;
  allItemsHaveFreight: () => boolean;
}
interface AddToCartInterface {
  cartItems: CartPartsItemInputCustom[];
}
interface SimpleCartItemProps {
  id: string;
  quantity: number;
}

export const SUPPLIER_CARRIER_CODE = 'suppliersdelivery';

export const CartContext = createContext<CartContextProps>({} as any);

const getSupplierAndBranchesFromItems = (data): ProductsSupplierBranch[] => {
  const arr: ProductsSupplierBranch[] = [];
  data?.items?.forEach((it) => {
    const filtered = arr.filter((me) => me.branch == it?.branchId && me.supplier == it?.supplierId);
    if (filtered && filtered.length) {
      filtered[0].products.push(it?.catalogId!);
    } else {
      arr.push({
        branch: it?.branchId,
        supplier: it?.supplierId,
        products: [it?.catalogId!],
      } as ProductsSupplierBranch);
    }
  });
  return arr;
};

const checkAllItemsHaveFreight = (data) => {
  const supplierBranches = getSupplierAndBranchesFromItems(data);
  const selectedFreight = data?.shipping_addresses[0]?.selected_shipping_method;
  if (
    supplierBranches &&
    supplierBranches.length &&
    selectedFreight &&
    selectedFreight.supplier_option_selected &&
    selectedFreight.supplier_option_selected.length > 0
  ) {
    const selectedOptions = selectedFreight.supplier_option_selected;
    for (let i = 0; i < supplierBranches.length; i++) {
      if (
        !selectedOptions.some(
          (it) => it?.branch == supplierBranches[i].branch && it?.supplier == supplierBranches[i].supplier
        )
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const CartProvider: React.FC = ({ children }) => {
  const [isSettingProviderId, setIsSettingProviderId] = useState(false);
  const { providerId, providerAddress, loadingProvider, provider } = useContext(AuthenticationContext);
  const { actions } = useAlerts();
  const client = useApolloClient();
  const { sub } = useContext(EventContext);
  const [addPartsProductsToCart] = useAddPartsProductsToCartMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [setAddressesOnCart] = useSetAddressesOnCartMutation();
  const [setShippingMethod, { loading: loadingShippingMethods }] = useSetPartsShippingMethodsOnCartMutation();
  const [updateCartItems] = useUpdateCartItemsMutation();
  const [removeItemsFromCart] = useRemoveItemsFromCartMutation();
  const [clearPaymentMethodGql] = useClearPaymentMethodOnCartMutation();
  const [setPaymentMethodGql] = useSetPaymentMethodOnCartMutation();
  const [placePartsOrder] = usePlacePartsOrderMutation({ fetchPolicy: 'network-only' });
  const [applyCoupon] = useApplyCouponMutation();
  const [removeCoupon] = useRemoveCouponMutation();

  const { data: buyerCartResponse, loading: loadingCart, refetch } = usePartnerPartsCartQuery({ skip: !providerId });
  const data = buyerCartResponse?.partnerPartsCart as Cart;
  const cartId = data?.id;

  const setCouponCodeHandler = useCallback(
    async (code: string) => {
      if (code && cartId) {
        try {
          setLoading(true);
          await applyCoupon({ variables: { cartId: cartId as string, code } });
        } catch (e: any) {
          console.error(e);
          throw new Error(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [cartId]
  );

  const removeCouponHandler = useCallback(async () => {
    if (cartId) {
      try {
        setLoading(true);
        await removeCoupon({ variables: { cartId: cartId as string } });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }, [cartId]);

  const placeOrderHandler = useCallback(
    async (invoiceObs?: string): Promise<[PlaceOrderReturn, string?]> => {
      const paymentMethodCode = data?.selected_payment_method?.code as string;
      try {
        if (data?.id) {
          await setShippingAddressHandler();
          const response = await placePartsOrder({ variables: { cartId: data?.id, obs: invoiceObs } });
          const { data: placedOrderData } = response;
          if (placedOrderData?.placePartsOrder?.order?.id) {
            //Save placed order to use on confirmation page
            placedOrder({
              cart: { ...(data as Partial<Cart>) },
              order: { ...placedOrderData?.placePartsOrder?.order },
              payment: { cart_id: data.id, payment_method: { code: paymentMethodCode } },
              placedOrderTime: response?.extensions?.tracing?.endTime,
            });

            if (paymentMethodCode === 'pix' || paymentMethodCode === 'financiamento') {
              actions?.addSuccessAlert('Seu pedido foi criado, aguardando confirmação de pagamento');
            }
          } else {
            actions?.addDangerAlert('Não foi possível concluir o pedido, tente novamente mais tarde.');
          }
        }
        return [PlaceOrderReturn.OK];
      } catch (e: any) {
        console.error('Exception: ', e);
        let obj;
        try {
          obj = JSON.parse(e.message);
        } catch (e) {}

        if (obj?.KEY == PlaceOrderReturn.OUT_OF_STOCK) {
          const error_message = `Ops! Saldo de estoque do produto ${obj.catalog} insuficiente para esta quantidade de itens.`;
          actions?.addDangerAlert(error_message);
          refetch();
          return [PlaceOrderReturn.OUT_OF_STOCK];
        } else if (e.message?.includes('Cannot deliver to different business units')) {
          return [PlaceOrderReturn.VALIDATION_ERROR];
        } else {
          console.error('Error setting payment method', e);
          const message = e.message.replace(/.*?Erro ao processar o pagamento: /, '');
          return [PlaceOrderReturn.PAYMENT_ERROR, message];
        }
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  const setPaymentMethodHandler = useCallback(
    async (paymentMethodInput: SetPaymentMethodOnCartInput) => {
      if (cartId) {
        try {
          setLoading(true);
          await setPaymentMethodGql({ variables: { input: paymentMethodInput } });
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [cartId]
  );

  const clearPaymentMethodHandler = useCallback(
    async (input: ClearPaymentMethodOnCartInput) => {
      if (cartId) {
        try {
          setLoading(true);
          await clearPaymentMethodGql({ variables: { input: input } });
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [cartId]
  );

  const setShippingAddressHandler = useCallback(
    async (resetPayment = false) => {
      if (cartId && providerAddress && !providerAddressOnCartIsTheSame()) {
        try {
          const billingAddressInput: SetBillingAddressOnCartInput = {
            cart_id: cartId,
            billing_address: {
              address: providerAddress?.address,
            },
          };

          const response = await setAddressesOnCart({
            variables: {
              cartId,
              shippingAddress: [providerAddress],
              billingAddress: billingAddressInput,
            },
          });
          if (!response?.data?.setShippingAddressesOnCart?.cart) {
            console.error('Unable to set shipping address on cart');
          }

          if (resetPayment) clearPaymentMethodHandler({ cart_id: cartId });
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [cartId, providerId, providerAddress, data]
  );

  const setShippingMethodHandler = useCallback(
    async (shippingMethods: Array<CommerceSupplierDeliveryOptionInput>) => {
      try {
        setLoading(true);
        if (!cartId) {
          console.error('No cart ID found');
          return;
        }

        const input = {
          cart_id: cartId,
          shipping_methods: [
            {
              carrier_code: SUPPLIER_CARRIER_CODE,
              method_code: SUPPLIER_CARRIER_CODE,
              supplier_options: shippingMethods,
            },
          ],
        };

        const { data } = await setShippingMethod({ variables: { input } });
        return data;
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [cartId]
  );

  // Reset cart, remove references from cache and localstorage
  const resetCartHandler = async () => {
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'partnerPartsCart' });
    client.cache.gc();
    if (providerId) await refetch();
  };

  const clearCartHandler = async () => {
    data?.items?.length && (await handleRemoveCartItems(data?.items?.map((i) => parseInt(i?.id as string, 10))));
  };

  const AddToCartProps = useCallback(
    async (props: AddToCartInterface, itemNames: string[]) => {
      try {
        setLoading(true);
        if (cartId) {
          const providerPostalCode = provider?.address?.postalCode
            ? parseInt(provider?.address?.postalCode?.replace('-', '') || '')
            : 1001001; // fallback
          const { data } = await addPartsProductsToCart({
            variables: {
              cartId: cartId,
              items: props.cartItems,
              zipCode: providerPostalCode + '',
            },
          });
          GA.addToCart(props.cartItems, itemNames);

          return true;
        }

        console.error('Unable to add to the cart, no cartId');
        return false;
      } catch (error) {
        if (JSON.stringify(error).includes('Insufficient stock for this requested quantity!')) {
          return error;
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [cartId, provider]
  );

  const getGrandTotal = (cart) => cart?.prices?.grand_total?.value || 0;

  const updateCartHandler = useCallback(
    async (cartItem: SimpleCartItemProps[]) => {
      let stockEqual: Boolean = false;
      actions?.removeAllAlerts();
      let message = '';
      try {
        setLoading(true);
        let stockOk: Boolean = true;

        cartItem.forEach((product) => {
          data?.items?.forEach((item) => {
            if (item?.id === product.id) {
              const stock: number = item.stock!;
              if (stock < product.quantity) {
                stockOk = false;
                message = `Ops! Saldo de estoque do produto ${item.catalogName} é ${stock} itens.`;
                product.quantity = Number(stock);
              } else if (stock == product.quantity) {
                stockEqual = true;
                product.quantity = Number(stock);
              }
              if (stock < Number(product.quantity) + Number(item?.itemsInBox)) {
                message = `Ops! Saldo de estoque do produto ${item.catalogName} é ${stock} itens.`;
              }
            }
          });
        });

        const updatedCart = data?.items?.map((item) => {
          let updatedItem = cartItem.find((product) => item?.id === product.id);
          if (updatedItem) {
            if (item?.quantity! > updatedItem.quantity) {
              item?.catalogId && GA.removeFromCart([item.catalogName || '']);
            } else {
              item?.catalogId &&
                GA.addToCart(
                  [
                    {
                      catalogId: item.catalogId,
                      quantity: updatedItem.quantity,
                      supplierId: item.supplierId || '',
                      branchId: item.branchId || '',
                    },
                  ],
                  [item.catalogName || '']
                );
            }
            return {
              cart_item_id: Number(updatedItem.id),
              quantity: updatedItem.quantity,
            };
          } else {
            return {
              cart_item_id: Number(item?.id),
              quantity: item?.quantity,
            };
          }
        });

        if (cartId && updatedCart) {
          const { data } = await updateCartItems({
            variables: { cart_id: cartId, cart_items: updatedCart },
          });
        }
      } catch (e: any) {
        let obj;
        try {
          obj = JSON.parse(e.message);
        } catch (e) {}

        if (obj && obj.KEY == PlaceOrderReturn.OUT_OF_STOCK) {
          message = `Ops! Saldo de estoque do produto ${obj.catalog} insuficiente.`;
        } else {
          actions?.addDangerAlert(e.message);
        }
        return message;
      } finally {
        setLoading(false);
        if (stockEqual) {
          message = `Ops! Estoque atingiu o limite`;
        }
      }
      return message;
    },
    [cartId, data]
  );

  const handleRemoveCartItems = useCallback(
    async (cartItemIds: Array<number>) => {
      try {
        setLoading(true);
        const ids: string[] = [];
        data?.items?.forEach((cartItem) => {
          if (cartItemIds.includes(parseInt(cartItem?.id || '-1', 10))) {
            cartItem?.catalogId && ids.push(cartItem?.catalogName || '');
          }
        });
        GA.removeFromCart(ids);
        if (cartId && cartItemIds) {
          await removeItemsFromCart({ variables: { cart_id: cartId, cart_item_ids: cartItemIds } });
        }
      } finally {
        setLoading(false);
      }
    },
    [data, cartId]
  );

  // In case of logout, reset cart
  // This will remove any reference from cache and local storage
  useEffect(() => {
    // Subscribe to the logout event to clear customer's cart
    sub(Event.LOGOUT, () => {
      resetCartHandler();
    });
  }, []);

  useEffect(() => {
    // refresh cart everytime providerId changes
    providerId && refetch();
  }, [providerId]);

  useEffect(() => {
    if (data && providerAddress) {
      setShippingAddressHandler();
    }
  }, [data?.id]);

  // Listen to providerId change event to set provider address on cart
  useEffect(() => {
    sub(Event.SET_PROVIDER_ID, () => {
      setIsSettingProviderId(true);
    });
  }, []);

  // Hook to wait until cart is loaded after changing provider ID
  useEffect(() => {
    if (cartId && isSettingProviderId && providerAddress) {
      setIsSettingProviderId(false);
    }
  }, [cartId, isSettingProviderId, providerAddress]);

  const providerAddressOnCartIsTheSame = () => {
    if (providerAddress && cartId && data?.billing_address?.city && (data?.shipping_addresses?.length || 0) > 0) {
      const addressOnCart = data?.shipping_addresses[0];
      return (
        addressOnCart?.firstname == providerAddress?.address?.firstname &&
        addressOnCart?.lastname == providerAddress?.address?.lastname &&
        addressOnCart?.city == providerAddress.address?.city &&
        addressOnCart?.neighborhood == providerAddress.address?.neighborhood &&
        addressOnCart?.street[0] == providerAddress.address?.street[0] &&
        addressOnCart?.number == providerAddress.address?.number
      );
    }
    return false;
  };

  if (data?.available_payment_methods?.find((i) => i?.code === 'payment_service_financiamento')) {
  }

  return (
    <CartContext.Provider
      value={{
        loading: loading || loadingProvider || loadingCart,
        id: cartId,
        cart: data,
        refetch,
        addItem: AddToCartProps,
        setShippingMethod: setShippingMethodHandler,
        loadingShippingMethods,
        updateCart: updateCartHandler,
        removeItems: handleRemoveCartItems,
        getGrandTotal,
        setShippingAddress: setShippingAddressHandler,
        resetCart: resetCartHandler,
        clearCart: clearCartHandler,
        placeOrder: placeOrderHandler,
        setPaymentMethod: setPaymentMethodHandler,
        clearPaymentMethod: clearPaymentMethodHandler,
        setCoupon: setCouponCodeHandler,
        removeCoupon: removeCouponHandler,
        allItemsHaveFreight: () => checkAllItemsHaveFreight(data),
        setLoading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const { id, cart, addItem, updateCart, removeItems, loading, refetch, ...contextProps } = useContext(CartContext);

  const addItemsToCartHandler = useCallback(
    async (addToCartProps: AddToCartInterface, itemNames: string[]) => {
      return await addItem(addToCartProps, itemNames);
    },
    [cart]
  );

  const getTotalItemsInTheCart = () => cart?.items?.reduce((acc, item) => acc + item!.quantity, 0) || 0;

  return {
    cartId: id,
    cart,
    refetch,
    add: addItemsToCartHandler,
    updateCart,
    removeItems,
    getTotalItemsInTheCart,
    loading,
    ...contextProps,
  };
};
